import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Paragraph from "../components/Paragraph";
import Link from "../components/Link/Link";
import Slab from "../components/Slab";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Slab className="mb-32 border-t border-grey-medium">
      <h1 className="text-white font-bold antialiased padded-multi-line-black ml-2">
        Sorry!&nbsp;
      </h1>
      <Paragraph>
        The page your are looking for does not exist. Please check spelling or
        return to the{" "}
        <Link className="text-green font-bold antialiased underline" to="/">
          HepBMD home page
        </Link>
      </Paragraph>
    </Slab>
  </Layout>
);

export default NotFoundPage;
